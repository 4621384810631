import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import NavV2 from "../components/NavV2"
import DefaultPageLight from "../components/DefaultPageLight"
import PasswordReset from "../components/PasswordReset"
import FooterContainer from "../components/FooterContainer"
import ResponsiveAppBar from "../components/ResponsiveAppBar"

const PasswordResetStart = () => (
  
  <>
    <ResponsiveAppBar />

    <Body>
      <DefaultPageLight
        title="Password Reset"
        subtitle="Please reset your password"
        />
        <PasswordReset/>
              <FooterDiv>
        <FooterContainer />
      </FooterDiv>
    </Body>



  </>
)

export default PasswordResetStart

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  background-color: #8bbef3;
`
const FooterDiv = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  left: 0px;
  top: 700px;
`